<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="MSDS_SEARCH_CND"
            label="검색구분"
            itemText="codeName"
            itemValue="code"
            name="searchCnd"
            type="none"
            v-model="searchParam.searchCnd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            label="검색어"
            name="searchNm"
            v-model="searchParam.searchNm">
          </c-text>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="MSDS 화학물질 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
        <template v-slot:customArea="{ props,col }">
          <template>
            <q-chip
              v-if="col.name==='msdslink' && props.row.chemId != ''"
              outline square
              color="blue"
              :clickable="true"
              @click.stop="() => msdslink(props.row)"
              text-color="white">
              MSDS
            </q-chip>
          </template>
        </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
export default {
  name: "koshamsdsd",
  data() {
    return {
      grid: {
        columns: [
          {
            name: "casNo",
            field: "casNo",
            label: "CAS No.",
            style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: 'msdslink',
            field: 'msdslink',
            label: 'MSDS',
            type: 'custom',
            align: 'center',
            sortable: false,
            style: 'width:100px',
            helpcomment: '안전보건공단에서 제공하는 MSDS정보'
          },
          {
            name: "chemNameKor",
            field: "chemNameKor",
            label: "화학물질명",
            align: "left",
            type: 'link',
            sortable: true,
          },
          {
            name: "enNo",
            field: "enNo",
            label: "EU No.",
            style: 'width:150px',
            align: "center",
            sortable: true,
          },
          {
            name: "keNo",
            field: "keNo",
            style: 'width:150px',
            label: "KE No.",
            align: "center",
            sortable: true,
          },
          {
            name: "unNo",
            field: "unNo",
            style: 'width:150px',
            label: "UN No.",
            align: "center",
            sortable: true,
          },
          {
            name: "lastDate",
            field: "lastDate",
            style: 'width:150px',
            label: "최종갱신일",
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      listUrl: '',
      msdsDetailUrl: '',
      charmDetailUrl: '',
      searchParam: {
        searchCnd: '0',
        searchNm: '',
      },
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = '/api/openapi/kosha/msdses';
      this.msdsDetailUrl = '/api/openapi/kosha/msds';
      this.charmDetailUrl = '/api/openapi/kosha/charms';
      // code setting
      // list setting
      this.getList()
    },
    getList() {
      if (this.searchParam.searchNm) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.grid.data = _result.data.item
        },);
      }
    },
    msdslink(row) {
      window.open('https://msds.kosha.or.kr/MSDSInfo/kcic/msdsdetail.do?chem_id='+row.chemId+'&viewType=msds','msdspop','width=1280, height=768, menubar=no, status=no, toolbar=no');
    },
    linkClick(row, col) {
    console.log(row)
      if (col.name === 'chemNameKor') {
        this.$http.url = this.msdsDetailUrl;
        this.$http.type = 'PUT';
        this.$http.param = {
          chemId: row.chemId
        };
        this.$http.request((_result) => {
          console.log('# msds: ', _result.data)
        },);
        this.$http.url = this.charmDetailUrl;
        this.$http.type = 'PUT';
        this.$http.param = {
          chemId: row.chemId
        };
        this.$http.request((_result) => {
          console.log('# charm: ', _result.data)
        },);
      }
      // this.popupOptions.title = "MSDS 정보";
      // this.popupOptions.param = {
      //   chemId: row ? row.chemId : '',
      // };
      // this.popupOptions.target = () => import(`${"./dangerRegularCheckTab.vue"}`);
      // this.popupOptions.visible = true;
      // this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  },
};
</script>
